<template>
  <b-field>
    <b-tag rounded :type="styleConfig.type" size="is-medium">
      <div class="is-flex is-align-items-center mx-2">
        <b-icon
          icon-pack="fa"
          :icon="styleConfig.icon"
          custom-class="mr-1 status__icon"
        />
        <span>
          {{ statusText }}
        </span>
      </div>
    </b-tag>
  </b-field>
</template>

<script>
import { PURCHASE_STATUS } from "@/utils/const";

export default {
  name: "PurchaseStatus",
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    styleConfig() {
      let config;
      switch (this.status) {
        case PURCHASE_STATUS.CREATED:
          config = { icon: "eye", type: "is-warning" };
          break;
        case PURCHASE_STATUS.LINKED:
          config = { icon: "eye", type: "is-warning" };
          break;
        case PURCHASE_STATUS.APPLICATION_STARTED:
          config = { icon: "eye", type: "is-warning" };
          break;
        case PURCHASE_STATUS.APPLICATION_UNDER_REVIEW:
          config = { icon: "eye", type: "is-warning" };
          break;
        case PURCHASE_STATUS.LOAN_APPROVED:
          config = { icon: "check", type: "is-info" };
          break;
        case PURCHASE_STATUS.MONEY_TRANSFERRED:
          config = { icon: "piggy-bank", type: "is-info" };
          break;
        case PURCHASE_STATUS.PAID:
          config = { icon: "check-double", type: "is-success" };
          break;
        default:
          config = { icon: "ban", type: "is-danger" };
      }
      return config;
    },
    statusText() {
      return this.$t(`af:purchase.status.${this.status.toLowerCase()}`);
    },
  },
};
</script>

<style lang="scss">
.icon {
  .status__icon {
    color: white !important;
  }
}
</style>
